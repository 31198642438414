
import { PAN_CONTAINER_ID } from "@/lib/pan";
import router from "@/router";
import { clientStore } from "@/store/client";
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  name: "ViewLoginAsClient",
  setup() {
    const isLogin = clientStore.isLogin;
    const panContainerId = PAN_CONTAINER_ID;
    const login = () => {
      clientStore.login(() => {
        router.push({ name: "ClientIncomeOverview" });
      });
    };
    onMounted(() => {
      if (!isLogin.value) {
        login();
      }
    });
    return {
      login,
      panContainerId,
    };
  },
});
